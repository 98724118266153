import {Calendar} from './Calendar'
import {DatePickerTextField} from './DatePickerTextField'
import {PickerForm} from './PickerForm'
import {QuickSelection} from './QuickSelection'
import {TabBar} from './TabBar'

export type {SimpleDatePickerProps} from './SimpleDatePicker'
export {SimpleDatePicker, datePickerOnBlueSx} from './SimpleDatePicker'
export {DateRangePicker} from './DateRangePicker'

const SimplePickerComponents = {
  Calendar,
  DatePickerTextField,
  PickerForm,
  QuickSelection,
  TabBar
}

export {SimplePickerComponents}
