import {DocumentUpdate, InstructionPayload} from '../types/documents.types'

export const prepareInstructionFormData = (instruction: DocumentUpdate): FormData => {
  const formData = new FormData()
  const instructionPayload: InstructionPayload = {
    title: instruction.title,
    processStage: instruction.processStage,
    validFrom: instruction.validFrom,
    validTo: instruction.validTo,
    link: instruction.link,
    attachment: instruction.newAttachment
  }
  const keys = Object.keys(instructionPayload)
  for (const key of keys) {
    const value = instructionPayload[key]
    if (value) {
      switch (key) {
        case 'priority':
          if (value !== 0) {
            formData.append(key, value as string | Blob)
          }
          break
        default:
          formData.append(key, value as string | Blob)
      }
    }
  }

  return formData
}
