import {SUMMARY_SEGMENT} from '@hconnect/common/hproduce/consts'

export const routeToEvents = '/:plantId/events'
export const routeToTasks = '/:plantId/tasks'
export const routeToSummary = `/:plantId/${SUMMARY_SEGMENT}`
export const routeToManageShifts = '/:plantId/shifts'
export const routeToRecurringTasks = '/:plantId/recurring-tasks'
export const routeToDocuments = '/:plantId/documents'
export const routeToTermsConditions = '/:plantId/tc'
export const routeToPrivacyPolicy = '/:plantId/privacy'

type LinkTo = {
  to: string
  translationKey: string
  keepURLParams?: string[]
}

export const links: LinkTo[] = [
  {
    to: routeToTasks,
    translationKey: 'tasks.pageName'
  },
  {
    to: routeToEvents,
    translationKey: 'shiftEvent.pageName'
  },
  {
    to: routeToSummary,
    translationKey: 'summary.pageName'
  },
  {
    to: routeToDocuments,
    translationKey: 'documents.pageName'
  }
]
