import {EquipmentData, RunningTime, RunningTimeType} from '@hconnect/common/types'
import {getEventStoppageType, isStoppageTypeWithReason} from '@hconnect/common/utils'
import moment from 'moment-timezone'

import {convertMinsToHrsMins} from '../common/utils/datetime'
import {ShiftEvent} from '../types/shiftHandover.types'

export const generateStoppageDefaultData = ({
  runningTime,
  isLast,
  equipment,
  suggestedMainEquipment
}: {
  runningTime: RunningTime
  isLast: boolean
  equipment: EquipmentData
  suggestedMainEquipment?: EquipmentData
}): Partial<ShiftEvent> => {
  const stoppageType = getEventStoppageType(equipment)

  return {
    stoppageStart: runningTime.beginTechnical,
    stoppageEnd: isLast ? undefined : runningTime.endTechnical,
    stoppageType,
    pxTrendTag: equipment.id,
    pxTrendStoppage: runningTime,
    ...(suggestedMainEquipment ? {mainEquipment: suggestedMainEquipment} : {}),
    ...(isStoppageTypeWithReason(stoppageType) ? {stoppageReason: undefined} : {})
  }
}

export const runningTimesToHrsMins = (runningTimes: RunningTime[] = []) => {
  return convertMinsToHrsMins(
    runningTimes
      .filter((rt: RunningTime) => rt.runningTimeType === RunningTimeType.RUNNING)
      .reduce(
        (sum: number, current: RunningTime) =>
          sum + moment(current.end).diff(moment(current.begin), 'minutes'),
        0
      )
  )
}
