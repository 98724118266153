import {ViewMode} from '@hconnect/common/components/eventsList/types'
import React, {useEffect} from 'react'
import {generatePath, useNavigate} from 'react-router-dom'

import {DocumentsView} from '../containers/documents/DocumentsView'
import {useFilteredInstructions} from '../hooks/api/useFilteredInstructions'
import {useConfig} from '../hooks/useConfig'
import {useInstructionsPageLastVisited} from '../hooks/useInstructionsPageLastVisited'
import {useQueryParameterForViewMode, viewModeToUrlParams} from '../hooks/useQueryParameter'
import {routeToDocuments} from '../routes'
import {DocumentSearchCriteria} from '../types/documents.types'

const createUrl = (nextViewMode: ViewMode, plantId: string): string => {
  const viewModeParams = viewModeToUrlParams(nextViewMode)
  return `${generatePath(routeToDocuments, {plantId})}?${viewModeParams}`
}

export const DocumentsPage = () => {
  const viewMode = useQueryParameterForViewMode()
  const navigate = useNavigate()
  const {plantId} = useConfig()

  // todo add filters
  const filterSettings: DocumentSearchCriteria = {}
  const eventQuery = useFilteredInstructions(filterSettings)
  const {setLastVisited} = useInstructionsPageLastVisited()

  useEffect(() => {
    setLastVisited()
    return () => {
      setLastVisited()
    }
  }, [setLastVisited])
  return (
    <DocumentsView
      documents={eventQuery.data}
      isLoading={eventQuery.isLoading || eventQuery.isFetching}
      viewMode={viewMode}
      setViewMode={(nextViewMode) => {
        navigate(createUrl(nextViewMode, plantId))
      }}
    />
  )
}
