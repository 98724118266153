import {AttachmentFile} from '@hconnect/common/types'
import moment from 'moment'

import {DateUTC} from './atomic.types'

export enum InstructionStatus {
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export enum InstructionType {
  LINK = 'LINK',
  PDF = 'PDF'
}

export type Instruction = {
  id: string
  title: string
  processStage: string
  validFrom: DateUTC
  validTo?: DateUTC
  attachment?: string
  attachmentFileName?: string
  link?: string
  createdAt: DateUTC
  updatedAt: DateUTC
}

export type InstructionsResponse = Instruction[]

export class InstructionVM {
  public id: string
  public title: string
  public processStage: string
  public validFrom: DateUTC
  public status: InstructionStatus
  public type: InstructionType
  public validTo?: DateUTC
  public attachment?: string
  public attachmentFileName?: string
  public link?: string
  public createdAt: DateUTC
  public updatedAt: DateUTC
  public deleteDayCounter?: number

  constructor(data: Instruction) {
    this.id = data.id
    this.title = data.title
    this.processStage = data.processStage
    this.validFrom = data.validFrom
    this.validTo = data.validTo
    this.attachment = data.attachment
    this.attachmentFileName = data.attachmentFileName
    this.link = data.link
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt

    if (moment().isBefore(data.validFrom)) {
      this.status = InstructionStatus.UPCOMING
    } else if (moment().isAfter(data.validTo)) {
      this.status = InstructionStatus.EXPIRED
      this.deleteDayCounter = moment(data.validTo).add(31, 'days').diff(moment(), 'days')
    } else {
      this.status = InstructionStatus.ACTIVE
    }
    this.type = data.attachment ? InstructionType.PDF : InstructionType.LINK
  }
}

export type DocumentUpdate = InstructionVM & {
  newAttachment?: AttachmentFile
}

export type PossibleErrors = keyof DocumentUpdate

export type InstructionPayload = {
  title: string
  processStage: string
  validFrom: DateUTC
  validTo?: DateUTC
  attachment?: AttachmentFile
  link?: string
}

export type DocumentSearchCriteria = {
  freeText?: string
  processStage?: string
  status?: InstructionStatus
}
